import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "shared-mobility"
    }}>{`Shared mobility`}</h1>
    <p>{`The shared mobility API provides functionality for booking a shared mobility trip and making transport operators' shared mobility products available for sale.`}</p>
    <p>{`The app serves as an API layer between clients who wish to offer trips with shared mobility through their channels and transport operators who provide these products.
The API facilitates bookings of products through transport operators' APIs that support the TOMP standard.`}</p>
    <p><img alt="Shared mobility architecture" src={require("./static/architecture.png")} /></p>
    <h2 {...{
      "id": "services"
    }}>{`Services`}</h2>
    <p>{`shared-mobility, shared-mobility-to-ref`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Service`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-shared-mobility-api"
            }}>{`shared-mobility`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is the main application for the shared mobility team, which facilitates the sale of products from transport operators that support the TOMP API standard. It includes functionality for clients to start, pause, and end a trip with a shared mobility product and has full integration with Entur's sales platform for creating orders, processing payments, sending receipts to travelers, and handling settlements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-shared-mobility-to-ref-api"
            }}>{`shared-mobility-to-ref`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Reference application for transport operators for how to implement the TOMP API standard`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "guides"
    }}>{`Guides`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Guide`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-shared-mobility-client-guide"
            }}>{`Client guide`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Guide for how to sell shared mobility through the shared-mobility API`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-shared-mobility-transport-operator-guide"
            }}>{`Transport operator guide`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Guide for how to support the TOMP API standard and make products available for sale`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "sequence-diagram"
    }}>{`Sequence diagram`}</h2>
    <p><img alt="Scooter trip" src={require("./static/sequence-diagram.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      